/* Global Overflow Scrollbar */
.layout-sidebar::-webkit-scrollbar {
    display: none;
}

.layout-sidebar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
    width: 10px;
	background-color: #F5F5F5;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background:  rgba(0,0,0,.8); 
    overflow-x: hidden;
}

/* Clear Fix */
.clearfix {
    overflow: auto;
}

.py-1-custom {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}

/* Container Class */
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media screen and (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media screen and (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
/* Custom Summary Card */
.summary-card {
    background: var(--bgColor);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.2px);
    -webkit-backdrop-filter: blur(6.2px);
    border: 1px solid rgba(217, 138, 138, 0.46);
}
.summary-box {
background: rgba(255, 255, 255, 0.05);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(6.8px);
-webkit-backdrop-filter: blur(6.8px);
border: 1px solid white;

}

.success {
    color: 'green'
}


/* Custom Speed Dial */
.custom-right-speed-dial {
    position: relative!important;
    justify-content: flex-start;
}

.custom-right-speed-dial .p-speeddial-button.p-button.p-button-icon-only {
    height: 3rem!important;
    width: 3rem!important;
}

.speeddial-tooltip .p-speeddial-direction-right.speeddial-top {
    top: 0;
    bottom: 0;
}

/* DROPDOWN PRIMEREACT */
.p-dropdown-panel {
    z-index: 3500!important;
}

/* DATATABLE */
text {
    stroke: unset!important;
}

.obs-table-chart {
    overflow-y: hidden;
    overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.obs-table-chart::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.obs-table-chart {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.obs-table-chart .p-datatable .p-datatable-tbody > tr > td:first-child  {
    padding: 5px!important;
}

.obs-table-chart .p-datatable .p-datatable-tbody > tr > td:not(:first-child) {
    padding: 0!important;
}
/* TOOL TIP */
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
  }
  
/* Tooltip text */
.tooltip .tooltiptext-top {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 3500;
}

.tooltip .tooltiptext-top {
    width: 120px;
    bottom: 100%;
    left: 50%; 
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Tooltip text */
.tooltip .tooltiptext-bottom {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 3500;
}

.tooltip .tooltiptext-bottom {
    width: 120px;
    top: 100%;
    left: 50%; 
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
  
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext-top {
    visibility: visible;
}

.tooltip .tooltiptext-top::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  /* patientForm  */
  .p-datepicker{
    z-index: 1111 !important;
  }

  /* Avatar */
  .p-avatar.p-avatar-lg {
    height: 3rem;
    width: 3rem;
  }

  .p-avatar.p-avatar-gl .p-avatar-icon {
    font-size: 1.4rem;
  }

  /* Muiti button Obs Form */
 #activeObs.p-selectbutton .p-button.p-highlight {
    background: #cd5c5c!important;
    border-color: #cd5c5c!important;
  }

  #activeObs .p-button:focus{
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #cd5c5c , 0 1px 2px 0 black !important;
  }
  /* Menu */
  .p-menuitem {
    padding: 5px 3px
  }

  .p-menuitem-link {
    background-color: rgba(255, 87, 87, 0.2);
    padding: 3px
  }

  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: red
  }


  /* Custom Sidebar */
  .custom-sidebar .p-sidebar-header {
    justify-content: space-between!important;
  }

  .p-sidebar-close {
    height: 2.5rem!important;
    width: 2.5rem!important;
    margin-right: 16px;
  }

  .p-sidebar-close-icon {
    font-size: 2rem;
  }

  .required-error {
    color: red!important
  }

  .p-dropdown .p-dropdown-label {
    font-size: 1.25rem;
  }

  /* Pigeon Map */
  .pigeon-map-wrapper {
    padding-top: 20px;
    width: 100%!important;
    height: 50vh!important;
    overflow: hidden;
  }    

  .pigeon-tiles-box {
    height: 50vh!important
  }

  .pigeon-overlays {
    height: 50vh!important
  }

  .pigeon-attribution {
    display: none;
  }

  .p-rowgroup-header-name {
    font-weight: bold;
  }

  .p-tabview-title {
    padding-left: 5px;
    padding-right: 5px;
  }

  .small-avatar {
    height: 2rem!important;
    width: 2rem!important;
  }

  .p-multiselect-panel {
    z-index: 3500 !important;
  }

  /* TreeTableDemo.css */

.treetable-responsive .sm-visible {
  display: none;
}

@media screen and (max-width: 40em) {
  .treetable-responsive .sm-invisible {
      display: none;
  }

  .treetable-responsive .sm-visible {
      display: inline;
      margin-right: .5rem;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 80vh;
  padding-top: 56.25%; 
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}