.card {
    background-color: var(--surface-card);
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: $borderRadius;

    &.card-w-title {
        padding-bottom: 2rem;
    }
}
