/* Chat Container */
.chat-area {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
    overflow-y: auto;
    min-height: 60vh;
    max-height: 60vh;
    padding: 20px;
  }
  
.chat-area .chat-bubble {
    background-color: #e4e4e4;
    padding: 20px;
    border-radius: 8px;
    max-width: 300px;
    word-wrap: break-word;
}
  
.chat-bubble .chat-time {
    font-size: 12px;
    color: #757575;
    text-align: right;
}
  
.chat-area .chat-bubble.current-user {
    margin-left: auto;
    background-color: rgba(99, 102, 241, 0.2);
}
  
.form-control {
    width: 60%;
}
  
.form-control:focus {
    box-shadow: none;
}
  
@media screen and (max-width: 767px) {
    .form-control {
      width: 100%;
    }
  
    .chat-area .chat-bubble {
      max-width: 250px;
    }
}